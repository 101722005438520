.container {
  padding: 1rem;
}

.logo svg{
  transform: scale(.60);
  transform-origin: left;
}

.virtualTourButton {
  display: none;
}

.themeButton {
  display: none;
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .container {
    padding: 1rem 3.125rem;
  }

  .virtualTourButton {
    display: flex;
    width: 33.33%;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10.6875rem;
    color: var(--logo-color);
  }

  .logo svg{
    transform: scale(1);
    transform-origin: initial;
  }

  .themeButton {
    display: flex;
    width: 33.33%;
    justify-content: flex-end;
    align-items: center;
  }

  .menuButton {
    display: none;
  }

}
