.textInput {
  height: 2.625rem;
  border-bottom: 1px solid var(--input-text-bottom-border-color);
  color: var(--main-container-foreground-color);
  padding: 0 0.375rem;
  letter-spacing: .1rem;
  font-size: .875rem;
  box-sizing: border-box;
  width: 100%;
  font-family: 'Gotham-Light', serif;
}